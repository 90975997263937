
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





















































































































































































































































// -------- On page load state -----------
.auth-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  @include sm-up {
    flex-direction: row;
  }

  .logo-link-portal {
    position: absolute;
    top: 0;
    left: 0;
    margin: 2.5rem 3rem;
  }

  .panel > .flex-container {
    display: flex;
    align-items: center;
    height: calc(50 * var(--vh, 1vh));
    width: 100%;

    @include sm-up {
      height: 100vh;
    }
  }

  .signup-panel {
    position: relative;
  }

  .inner-panel,
  .inner-panel .flex-container {
    display: flex;
    align-items: flex-start;
    position: absolute;
  }

  .inner-panel {
    width: 100vw;
    background-color: $--clb-color-primary__white;

    @include sm-up {
      width: 50vw;
    }

    &__with-form {
      height: 100vh;
    }
  }

  .auth-view-left {
    width: 100vw;
    height: calc(50 * var(--vh, 1vh));
    background-color: $--clb-color-grey__white-ter;

    @include sm-up {
      width: 100vw;
      height: 100vh;
    }

    > .flex-container {
      background-color: $--clb-color-grey__white-ter;
      justify-content: center;
    }

    .auth-panel-copy {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.2rem;
      margin-bottom: 0;
      margin-top: 0;
    }

    .auth-panel-header {
      font-size: 2.4rem;
      margin-top: 0;
    }

    .auth-form {
      width: 90vw;
      padding: $--clb-layout-5 0 0 0;

      @include sm-up {
        width: 20rem;
      }

      @include md-up {
        width: 28.75rem;
        padding: $--clb-layout-4 0 0 0;
      }

      .auth-header {
        margin-top: 0;
        font-size: 3rem;
        margin-bottom: 1rem;
      }

      .auth-sub-header {
        font-size: 1rem;
        width: 18rem;
        margin: 2rem auto;
        color: $--clb-body-font;

        @include xs-up {
          width: 20rem;
        }
      }
    }

    .auth-panel-img-cont {
      max-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $--clb-color-primary;

      .auth-panel-image {
        display: none;

        @include sm-up {
          display: flex;
          width: auto;
          height: 360px;
          max-width: calc(50vw - #{$--clb-mobile-padding * 2});
        }

        @include mobile-landscape {
          max-height: calc(50 * var(--vh, 1vh));
        }
      }
    }

    .inner-panel {
      transform: translateX(0);
      transition: opacity 0.3s ease;
    }

    &.active {
      border-right: 0.25rem solid #cccfdb;

      @include mobile-landscape {
        border: none;
      }

      > .flex-container {
        display: none;

        @include sm-up {
          display: flex;
        }
      }

      .inner-panel {
        @include sm-up {
          transform: translateX(50vw);
        }

        @media only screen and (max-height: 640px) {
          overflow-y: scroll;
        }

        transition: transform 0.3s ease;
      }
    }

    &.inactive {
      > .flex-container {
        transform: translateX(-50vw);
      }

      .inner-panel {
        transform: translateX(0);
      }
    }
  }

  .auth-view-right {
    @extend .auth-view-left;

    transform: translateX(0);
    background-color: $--clb-color-primary;

    > .flex-container {
      background-color: $--clb-color-primary;
    }

    .inner-panel {
      transform: translateX(0);
      transition: transform 0.3s ease;
    }

    .auth-panel-copy,
    .auth-panel-header {
      color: $--clb-color-primary__white;
    }

    &.active {
      border-right: 0;

      > .flex-container {
        display: none;

        @include sm-up {
          display: flex;
        }
      }

      .inner-panel {
        transform: translateX(0) translateY(calc(-50 * var(--vh, 1vh)));
        transition-duration: 0s;

        @include sm-up {
          transform: translateX(-50vw);
          transition: transform 0.3s ease;
        }
      }
    }

    &.inactive {
      > .flex-container {
        transform: translateX(50vw);
      }

      .inner-panel {
        transform: translateX(0);
      }
    }
  }

  // --------- On page load state ---------
  // -------- Styles for both sides -------
  .panel {
    .email-consent {
      text-align: left;

      .normal {
        color: $--color-text-regular !important;
      }
    }

    .take-me-back {
      width: 100%;
      font-size: 14px;
      margin-bottom: 32px;

      .attention {
        letter-spacing: 0.5px;
      }
    }

    // Active is clicked side
    &.active {
      display: flex;

      .auth-panel-img-cont {
        .auth-panel-copy {
          font-size: 1.5rem;
        }

        .auth-panel-header {
          font-size: 3rem;
        }

        .auth-panel-image {
          margin-top: 10px;
        }
      }

      // FORM
      .inner-panel {
        z-index: 12;
        pointer-events: auto;

        @include sm-up {
          z-index: 10;
          left: 0;
        }
      }

      .flex-container {
        z-index: 11;
        justify-content: center;
      }
    }
  }
}

.auth-view-right.inactive > .inner-panel__with-form > .lead-source-media-object {
  display: none;
}

.inner-panel.inner-panel__with-form > .auth-form {
  margin: auto;
}

.inner-panel.inner-panel__with-program {
  height: 100vh;

  > .auth-form {
    margin: auto;
  }
}

.transitioning {
  @include md-up {
    .panel,
    .panel > .flex-container {
      transition: opacity 0.6s ease-out;
    }
  }

  .auth-panel-copy,
  .auth-panel-header,
  .auth-panel-image {
    transition: all 0.6s cubic-bezier(0.83, -0.43, 0.21, 1.42);
  }

  .inner-panel {
    transition: background-color 0.6s ease-in;
  }
}
