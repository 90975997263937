
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

































































































.program-panel {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $--clb-color-primary__white;

  @include md-up {
    flex-direction: column;
  }

  &__left,
  &__right {
    width: 100vw;
    height: calc(50 * var(--vh, 1vh));
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include mobile-landscape {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-areas:
        "header desc"
        "cta desc";
    }

    @include sm-up {
      width: 50vw;
    }

    .circle-logo {
      width: 100px;
      height: 73px;
      margin: $--clb-space-2;
      margin-left: 0;
      border-radius: 36px;
    }

    &-desc {
      font-size: 0.75rem;
      width: 20rem;
      margin-top: 20px;
      grid-area: desc;

      @include md-up {
        width: 28rem;
        font-size: 0.9rem;
      }

      @include lg-up {
        width: 31rem;
        font-size: 1.1rem;
      }

      @include mobile-landscape {
        width: auto;
        margin: 0 $--clb-layout-3;
        justify-self: center;
      }
    }

    &-header {
      width: 11rem;
      display: flex;
      justify-content: space-around;
      grid-area: header;

      @include md-up {
        width: 22rem;
      }

      @include mobile-landscape {
        justify-self: center;
      }

      &--logo {
        width: 16rem;
        height: 100px;
      }

      &:hover + &-cta {
        cursor: pointer;
        border-radius: 0.5rem;
        border: solid 0.125rem $--clb-color-primary__white;
        padding: 1rem 2rem;
      }
    }

    &-cta {
      grid-area: cta;

      @include mobile-landscape {
        margin: 0 $--clb-space-4;
      }
    }
  }

  &__left {
    background: rgba(42, 173, 173, 1);

    &-header--logo {
      fill: $--clb-color-primary__white;
    }

    &-cta {
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      padding: 1rem 1rem;
      font-weight: 700;
      margin-top: 1.5rem;
      transition: border 0.3s ease, padding 0.3s ease;
      font-size: 0.75rem;
      background: none;
      border-radius: 0.5rem;
      border: solid 0.125rem transparent;
      color: $--clb-color-primary__white;
      cursor: pointer;

      @include md-up {
        font-size: 1rem;
      }

      @include mobile-landscape {
        margin-top: 0;
      }
    }
  }

  &__right {
    background: $--clb-color-secondary__dark;

    &-header--logo {
      fill: $--clb-color-primary__white;
    }

    &-cta {
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      padding: 1rem 1rem;
      font-weight: 700;
      margin-top: 1.5rem;
      transition: border 0.3s ease, padding 0.3s ease;
      font-size: 0.75rem;
      background: none;
      border-radius: 0.5rem;
      border: solid 0.125rem transparent;
      color: $--clb-color-primary__white;
      cursor: pointer;

      @include md-up {
        font-size: 1rem;
      }

      @include mobile-landscape {
        margin-top: 0;
      }
    }
  }

  .cta-border {
    border-radius: 0.5rem;
    border: solid 0.125rem $--clb-color-primary__white;
    padding: 1rem 2rem;
  }
}
