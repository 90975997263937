
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        































































































































































.auth-form {
  margin: auto;
  max-width: 460px;
  width: 100%;

  .forgot-link {
    float: right;
  }

  input[type='email']:disabled {
    color: $--color-primary;
  }

  .auth-header {
    margin-bottom: 0;
  }

  .auth-sub-header {
    font-size: $--jb-font-size-small;
    margin-bottom: 32px;
    margin-top: 8px;
  }

  @include b(form) {
    @include b(form-item) {
      &.floating-label {
        margin-bottom: 32px;

        @include e(error) {
          right: 2px;
          left: 2px;
          text-align: left;
          top: 64px;
        }
      }
    }

    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
