
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

















































































































.signup-panel {
  .lead-source-media-object {
    position: absolute;
    right: $--clb-mobile-padding;
    top: $--clb-mobile-padding;

    @include md-up {
      right: 40px;
      top: 40px;
    }
  }
}
