
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




















































































//Manager media object
.lead-source-media-object {
  height: 40px;

  &__image {
    border-radius: 50%;
    height: 40px;
    margin-right: 12px;
    top: -7.5px;
    width: 40px;

    &--carousel {
      fill: #0e276b;

      #Layer_2-2 > path {
        fill: $--clb-color-primary__white;
      }
    }
  }

  &__description {
    display: inline-block;
    font-size: $--jb-meta-desc-copy;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 2px;
    text-align: left;
  }

  &__title,
  &__name {
    clear: both;
    flex: 1;
    float: left;
  }

  &__title {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.6px;
  }

  &__name {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
  }
}

// MOBILE VIEW
@media (max-width: 768px) {
  .lead-source-media-object {
    top: $--clb-space-5;
    right: $--clb-space-5;
  }
}
